import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./pages/login/Login";
import Home from "./pages/Home/Home";
import News from "./pages/News/News";
import NewsDetail from "./pages/News/NewsDetail";
import Calendars from "./pages/Calendar/Calendars";
import "bootstrap/dist/css/bootstrap.min.css";
import Harassment from "./pages/HarassmentResponse/Documents";
import HarassmentDetail from "./pages/HarassmentResponse/DocumentDetail";
import HarassmentView from "./pages/HarassmentResponse/DocumentView";
import HealthSafety from "./pages/HealthSafety/Documents";
import HealthSafetyDetail from "./pages/HealthSafety/DocumentDetail";
import HealthSafetyView from "./pages/HealthSafety/DocumentView";
import { Documents } from "./pages/Documents/Documents";
import { DocumentDetail } from "./pages/Documents/DocumentDetail";
import DocumentView from "./pages/Documents/DocumentView";
import { Contacts } from "./pages/Contact/Contacts";
import { ContactDetail } from "./pages/Contact/ContactDetail";
import { Settings } from "./pages/Settings/Settings";
import { ContactAdmin } from "./pages/ContactAdmin/ContactAdmin";
import { FAQ } from "./pages/Settings/FAQ";
import { AboutApps } from "./pages/Settings/AboutApps";
import { UserProfil } from "./pages/Settings/UserProfil";
import PasswordReset from "./pages/PasswordReset/PasswordReset";
import PasswordResetChange from "./pages/PasswordReset/PasswordResetChange";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import Error404 from "./pages/Error404";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { customAxios } from "./api/customAxios";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function App() {
  const [alert, setAlert] = useState(false);
  const [alertStatus, setAlertStatus] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };

  // first render
  useEffect(() => {
    const dataUser = localStorage.getItem("updateUserProfile");
    const data = JSON.parse(dataUser);
    if (dataUser) {
      const formData = new FormData();
      formData.append("foto", data.foto);
      formData.append("email", data.email);
      formData.append("bio", data.bio);
      formData.append("unit", data.unit);

      customAxios({
        url: "/profile/change",
        method: "post",
        data: formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      })
        .then((res) => {
          setAlert(true);
          setAlertStatus("success");
          setAlertMessage("Data User Profile updated successfully!");
          customAxios(`/profile/${data.email}`);
          setTimeout(() => window.location.reload(), 3000);
          localStorage.removeItem("updateUserProfile");
        })
        .catch((err) => {
          setAlert(true);
          setAlertStatus("error");
          setAlertMessage("Something went wrong on the server!");
        });
    }
  }, []);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      function tes(event) {
        var condition = navigator.onLine ? "online" : "offline";
        if (condition === "offline") {
          setAlert(true);
          setAlertStatus("error");
          setAlertMessage("You’re offline. Check your connection.");
        }
      }
      window.addEventListener("online", tes);
      window.addEventListener("offline", tes);
    }
  }, [setAlert]);
  return (
    <Router>
      <div>
        <Snackbar open={alert} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={alertStatus}>
            {alertMessage}
          </Alert>
        </Snackbar>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/password-reset" component={PasswordReset}>
            <Switch>
              <Route path="/password-reset" exact component={PasswordReset} />
              <Route
                path="/password-reset/:tokenLink"
                component={PasswordResetChange}
              />
            </Switch>
          </Route>
          <Route path="/change-password" component={ChangePassword} />
          <Route path="/home" component={Home} />
          <Route path="/contact-admin" component={ContactAdmin} />
          <Route path="/settings" component={Settings} />
          <Route path="/calendar" component={Calendars} />
          <Route path="/news" component={News}>
            <Switch>
              <Redirect exact={true} from="/news/detail" to="/news/detail/1" />
              <Route path="/news" exact component={News} />
              <Route path="/news/detail/:idDetail" component={NewsDetail} />
            </Switch>
          </Route>
          <Route path="/documents" component={Documents}>
            <Switch>
              <Redirect
                exact={true}
                from="/documents/detail"
                to="/documents/detail/1"
              />
              <Route path="/documents" exact component={Documents} />
              <Route
                path="/documents/detail/:idDetail"
                component={DocumentDetail}
              />
              <Route
                path="/documents/view/:idDetail"
                component={DocumentView}
              />
            </Switch>
          </Route>
          {/* start health and safety */}
          <Route path="/health-and-safety" component={HealthSafety}>
            <Switch>
              <Redirect
                exact={true}
                from="/health-and-safety/detail"
                to="/health-and-safety/detail/1"
              />
              <Route path="/health-and-safety" exact component={HealthSafety} />
              <Route
                path="/health-and-safety/detail/:idDetail"
                component={HealthSafetyDetail}
              />
              <Route
                path="/health-and-safety/view/:idDetail"
                component={HealthSafetyView}
              />
            </Switch>
          </Route>
          {/* end health and safety */}
          {/* start harassment response */}
          <Route path="/harassment-response" component={Harassment}>
            <Switch>
              <Redirect
                exact={true}
                from="/harassment-response/detail"
                to="/harassment-response/detail/1"
              />
              <Route path="/harassment-response" exact component={Harassment} />
              <Route
                path="/harassment-response/detail/:idDetail"
                component={HarassmentDetail}
              />
              <Route
                path="/harassment-response/view/:idDetail"
                component={HarassmentView}
              />
            </Switch>
          </Route>
          {/* end harassment response */}
          <Route path="/contacts" component={Contacts}>
            <Switch>
              <Redirect
                exact={true}
                from="/contacts/detail"
                to="/contacts/detail/1"
              />
              <Route path="/contacts" exact component={Contacts} />
              <Route
                path="/contacts/detail/:idDetail"
                component={ContactDetail}
              />
            </Switch>
          </Route>
          <Route path="/faq" component={FAQ} />
          <Route path="/about" component={AboutApps} />
          <Route path="/user-profil" component={UserProfil} />
          <Route path="/404" component={Error404} />
          <Route component={Error404} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
