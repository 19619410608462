import React from 'react';
import Header from '../../components/Header';
import BotNav from '../../components/BotNav';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
// import CommentIcon from '@material-ui/icons/Comment';
import PersonIcon from '@material-ui/icons/Person';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import InfoIcon from '@material-ui/icons/Info';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    maxWidth: 600,
    flexGrow: 1,
    width: '90%'
  }
}));

const VersionBadge = withStyles((theme) => ({
  badge: {
    right: -20,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

export const Settings = () => {
  const classes = useStyles();
  let history = useHistory();
  const linkList = data => {
    history.push({
      pathname: data
    })
  }
  return (
    <>
    <Header />
    <div className={classes.root}>
      <h5 className="text-center my-4">
        Settings
      </h5>
      <List component="nav" aria-label="main mailbox folders">
        <ListItem button onClick={() => linkList("/user-profil")}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="User Profile" />
        </ListItem>
        {/* <ListItem button onClick={() => linkList("/faq")}>
          <ListItemIcon>
            <CommentIcon />
          </ListItemIcon>
          <ListItemText primary="FAQ" />
        </ListItem> */}
        <ListItem button onClick={() => linkList("/about")}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary={
              <Tooltip title={`App version ${process.env.REACT_APP_VERSION}`} placement="top-end">
                <VersionBadge badgeContent={process.env.REACT_APP_VERSION} color="secondary">
                  About Apps
                </VersionBadge>
              </Tooltip>
            } />
        </ListItem>
        <ListItem button onClick={() => linkList("/change-password")}>
          <ListItemIcon>
            <VpnKeyOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Change Password" />
        </ListItem>
        <ListItem button onClick={() => {
            linkList("/")
            localStorage.clear()
            window.location.reload()
          }}>
          <ListItemIcon>
            <PowerSettingsNewIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
    <BotNav />
  </>
  )
}
