import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { TextField, Snackbar, Box, IconButton, Modal, Fade, Button, MenuItem, Typography, FormControl, Select, InputLabel, Checkbox, ListItemText, Input, Chip } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Badge from '@material-ui/core/Badge';
import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { customAxios } from '../../api/customAxios';
import MuiAlert from '@material-ui/lab/Alert';
import { select_unit } from '../../utils/data';
import './Contacts.css';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#00502F'),
    fontSize: '14px',
    backgroundColor: '#00502F',
    '&:hover': {
      backgroundColor: '#15716d',
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    maxWidth: 600,
    flexGrow: 1,
    width: '90%'
  },
  text: {
    margin: '-.5rem 8px 16px 8px',
    fontSize: '22px',
    cursor: 'pointer'
  },
  highlight: {
    backgroundColor: '#DDDDDD60'
  },
  colorPrimary: {
    color: '#FFF',
    marginLeft: 8,
    backgroundColor: '#00502F',
    '&:hover': {
      backgroundColor: '#416d6b',
      boxShadow: 'none',
    },
  },
  fab: {
    zIndex: 999,
    position: 'fixed',
    bottom: 15,
    right: 25
  },
  paper: {
    maxWidth: 350,
    backgroundColor: theme.palette.background.paper,
    border: '5px double #00502F',
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 15px',
    justifyContent: 'center',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
}));

export const Contacts = () => {
  const classes = useStyles();
  let history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [loadNewItems, setLoadNewItems] = useState(true);
  const [newItems, setNewItems] = useState([]);
  const [dataContacts, setDataContacts] = useState([]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [selectCountry, setSelectCountry] = useState([]);
  const [selectState, setSelectState] = useState([]);
  const [dataKontak, setDataKontak] = useState([]);
  const [dataKontakFilter, setDataKontakFilter] = useState([]);
  const [highlight, setHighlight] = useState(null);
  const [unit, setUnit] = useState([]);

  const [alert, setAlert] = useState(false);
  const [alertStatus, setAlertStatus] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const linkDetail = data => {
    history.push({
      pathname: `/contacts/detail/${data.id_contact}`,
    })
  }

  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);

  useEffect(()=>{
    if(loadData){
      customAxios('/contacts').then(response=>{
        if(response.status === 200){
          // country
          const negara = response.data.data?.map((item) => item?.country);
          const dupNegara = [...new Set(negara)];
          const filNegara = dupNegara.filter(e => e);
          setSelectCountry(filNegara?.sort());

          // state
          const lokasi = response.data.data?.map((item) => item?.state);
          const dupLokasi = [...new Set(lokasi)];
          const filLokasi = dupLokasi.filter(e => e);
          setSelectState(filLokasi?.sort());

          setDataKontak(response.data.data)
          setDataKontakFilter(response.data.data)
          formatDataContacts(response.data.data)
          setLoadData(false)
        }
      }).catch(err => {
        setLoadData(false);
      })
    }
  },[loadData])

  useEffect(()=>{
    if(loadNewItems){
      const users = JSON.parse(localStorage.getItem('users'));
      customAxios(`/notifikasi/contact/${users.id}`)
      .then(response=>{
        if(response.status === 200){
          setNewItems([]);
          response.data.data.map(dt => {
            if (dt.flagid === 0 && dt.idn !== null){
              return setNewItems(newItems => [...newItems, dt.idn])
            }
            return null;
          })
          setLoadNewItems(false)
        }
      })
    }
  }, [loadNewItems])

  const textCari = (text, dataUnit) => {
    const search = dataKontak?.filter(item => {
      if (dataUnit?.length > 0) {
        if (!item.last_name) {
          return item.first_name.toLowerCase().includes(text) && dataUnit?.some(val => item?.unit?.includes(val))
        }
        return (item.last_name.toLowerCase().includes(text) || item.first_name.toLowerCase().includes(text)) && dataUnit?.some(val => item?.unit?.includes(val))
      }else{
        if (!item.last_name) {
          return item.first_name.toLowerCase().includes(text)
        }
        return item.last_name.toLowerCase().includes(text) || item.first_name.toLowerCase().includes(text)
      }
    })
    setDataKontakFilter(search);
    formatDataContacts(search);
  
  }

  const onDeleteItems = val => {
    const users = JSON.parse(localStorage.getItem('users'));
    customAxios.post('/notifikasi/resetnotifikasi', {
      category: 'contact',
      id: users.id,
      idn: val
    })
      .then(() => setLoadNewItems(true))
      .catch(error => console.log(error.message))
  };

  const onScroll = () => {
    try{
      let element = document.getElementById(newItems[0]);
      let headerOffset = 100;
      let elementPosition = element.getBoundingClientRect().top;
      let offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    
      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      });
    }catch(err){
      setAlert(true);
      setAlertStatus('error');
      setAlertMessage('Notif not found!');
    }
    setHighlight(newItems[0]);
    onDeleteItems(newItems[0]);
  }

  useEffect(()=>{
    if(highlight !== null){
      const timer = () => setTimeout(() => setHighlight(null), 3000);
      const timerId = timer();
      return () => {
        clearTimeout(timerId);
      };
    }
  },[highlight])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert(false);
  };

  const resetFilter = () => {
    window.location.reload();
    setOpenModal(false);
  }

  const saveFilter = () => {
    if (
        unit?.length === 0 &&
        country?.length === 0 &&
        state?.length === 0
      ){
        setAlert(true);
        setAlertStatus('error');
        setAlertMessage('select at least one option!');
        return;
    }
    let dataUnit = unit;
    const contacts = dataKontakFilter?.filter(item => {
      if (dataUnit?.length > 0) {
        if (dataUnit?.some(val => item?.unit === val)){
          if (country?.length > 0 || state?.length > 0) {
            if (
              country?.some(val => item?.country === val) ||
              state?.some(val => item?.state === val)
            ) {
              return true
            } else {
              return false
            }
          }
          return true
        }
      } else {
        if (country?.length > 0 || state?.length > 0) {
          if (
            country?.some(val => item?.country?.includes(val)) ||
            state?.some(val => item?.state?.includes(val))
          ) {
            return true
          } else {
            return false
          }
        }
      }
      return false
    });
    formatDataContacts(contacts);
    setOpenModal(false);
  }

  const formatDataContacts = (data) => {
    setDataContacts([]);
    setDataContacts(data.sort((a, b) => {
      if (!a.last_name) {
        return a.first_name.localeCompare(b.first_name)
      }
      return a.last_name.localeCompare(b.last_name)
    })
    .reduce((r, e) => {
      let key;
      if (!e.last_name){
        key = e.first_name[0].toUpperCase();
      }else{
        key = e.last_name[0].toUpperCase();
      }
      if(!r[key]) r[key] = []
      r[key].push(e);
      return r;
    }, {}));
  }

  return (
    <>
      <Snackbar open={alert} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertStatus}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Header link="/home" />
      <div className={classes.root}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <TextField
            type="search"
            placeholder="Search Contacts"
            label="" 
            variant="outlined" 
            fullWidth
            onChange={e => {
              textCari(e.target.value.toLowerCase(), unit);
            }}
            InputProps={{
              startAdornment: 
              <InputAdornment position="start">
                <SearchIcon color="disabled" />
              </InputAdornment>,
            }} />
          <IconButton onClick={handleModalOpen} className={classes.colorPrimary}>
            <FilterListIcon fontSize="small" />
          </IconButton>
        </Box>
          
        {
          newItems.length > 0 ?
          <div onClick={onScroll} className={classes.fab}>
            <Badge badgeContent={newItems.length} color="secondary">
              <Fab color="primary" aria-label="add" className={classes.colorPrimary}>
                <NotificationsActiveIcon fontSize='large' />
              </Fab>
            </Badge>
          </div> : null
        }

        <div className={classes.text}>
          {Object.entries(dataContacts).map(([key, value], i) => {
            return <div key={i}>
              <Row className="mt-2">
                <Col xs={12} md={12}>
                  <strong>{key}</strong>
                </Col>
              </Row>
              {value.map((item, j) => (
                <Row id={item.id_contact} key={j} className={highlight === item.id_contact && highlight !== null ? 'contact-highlight' : ''}>
                  <Col key={j} xs={12} md={12} onClick={() => linkDetail(item)}>
                    {
                      item.last_name !== null ? `${item.last_name}, ${item.first_name}`
                      : item.first_name
                    }
                  </Col>
                </Row>
                ))}
            </div>
          })}
        </div>
      </div>

      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        style={{display:'flex',alignItems:'center',justifyContent:'center'}}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <Typography variant="h6">Filter</Typography>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-mutiple-checkbox-label">Select Unit</InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                input={<Input />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
              >
                {select_unit.map((item) => (
                  <MenuItem key={item.id} value={item.value}>
                    <Checkbox checked={unit.indexOf(item.value) > -1} />
                    <ListItemText primary={item.label} primaryTypographyProps={{ style: { whiteSpace: "normal" } }} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-mutiple-checkbox-label">Select Country</InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                  setState([]);
                }}
                input={<Input />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
              >
                {selectCountry?.length > 0 ? selectCountry?.map((item, idx) => (
                  <MenuItem key={idx} value={item}>
                    <Checkbox checked={country.indexOf(item) > -1} />
                    <ListItemText primary={item} primaryTypographyProps={{ style: { whiteSpace: "normal" } }} />
                  </MenuItem>
                )) : <MenuItem disabled>No Data</MenuItem>}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-mutiple-checkbox-label">Select State</InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                  setCountry([]);
                }}
                input={<Input />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
              >
                {selectState?.length > 0 ? selectState?.map((item, idx) => (
                  <MenuItem key={idx} value={item}>
                    <Checkbox checked={state.indexOf(item) > -1} />
                    <ListItemText primary={item} primaryTypographyProps={{ style: { whiteSpace: "normal" } }} />
                  </MenuItem>
                )) : <MenuItem disabled>No Data</MenuItem>}
              </Select>
            </FormControl>
            <Box className="mt-4 d-flex flex-row">
              <Button
                variant="outlined"
                onClick={resetFilter}
                style={{ width: 100, borderColor: '#00502F', color: '#00502F' }}>
                RESET
              </Button>
              <ColorButton
                className="ml-2"
                variant="contained"
                color="primary"
                style={{ width: 100 }}
                onClick={saveFilter}>
                SAVE
              </ColorButton>
            </Box>
          </div>
        </Fade>
      </Modal>
    </>
  )
}
