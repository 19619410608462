import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import BotNav from '../../components/BotNav';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Image } from 'react-bootstrap';
import { customAxios } from '../../api/customAxios';
import NewsHome from '../../components/NewsHome';
import MenuHome from '../../components/MenuHome';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    margin: '0 auto 130px auto'
  },
  root: {
    margin: 'auto',
    maxWidth: 600,
    flexGrow: 1,
  },
  img: {
    maxHeight: 250,
    maxWidth: 600,
    overflow: 'hidden',
    width: '80%',
  }
}));

const urlImage = process.env.REACT_APP_UPLOADS + 'banners/';

const Home = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [loadData, setLoadData] = useState(true)
  const [arrData, setArrData] = useState([])
  const [maxSteps, setMaxSteps] = useState(0)

  useEffect(() => {
    if(loadData){
      customAxios('/banners').then(response => {
        if (response.status === 200) {
          const data = response.data.data
          setArrData(data)
          setMaxSteps(data.length)
        }
      }).catch(err => console.log(err.message))
      setLoadData(false)
    }
  }, [loadData])  

  const handleBanner = link => {
    if (link[0] === '#'){
      return
    }else{
      window.open(link, '_self')
    }
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div className={classes.container}>
      <Header />
      <React.StrictMode className={classes.root}>
        <AutoPlaySwipeableViews
          className="text-center"
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {arrData.map((step, index) => (
            <div key={step.id_banner}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Image 
                  onClick={() => handleBanner(step.link)} 
                  className={classes.img} 
                  src={urlImage + step.images} 
                  alt={step.title} 
                  rounded />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
          style={{ backgroundColor: '#FFFFFF00', color: '#FFFFFF00' }}
          steps={maxSteps}
          position="static"
          variant="dots"
          activeStep={activeStep}
          nextButton='.'
          backButton='.'
        />
      </React.StrictMode>
      <MenuHome />
      <NewsHome />
      <BotNav />
    </div>
  );
}

export default Home
