import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import { customAxios } from '../api/customAxios';

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 16,
    top: 16,
  },
}))(Badge);

const ItemMenu = ({ linkBadge, linkImage }) => {
  const [count, setCount] = useState(0);
  
  useEffect(()=>{
    customAxios(linkBadge)
      .then(response => {
        response.data.data.map(dt => {
          if (dt.flagid === 0 && dt.idn !== null){
            return setCount(count => count + 1)
          }
          return null;
        })
      })
  },[linkBadge])

  return (
    <StyledBadge badgeContent={count} color="secondary">
      <Image width="100%" src={process.env.PUBLIC_URL + linkImage} />
    </StyledBadge>
  )
}

export default ItemMenu