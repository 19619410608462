import React from 'react';
import Header from '../../components/Header';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    maxWidth: 600,
    flexGrow: 1,
    width: '90%',
    marginBottom: 24,
    fontSize: 13,
  },
  header: {
    fontSize: 20,
  },
  fontQ: {
    fontSize: 15,
  },
  linkEmail: {
    color: '#0000EE',
  }
}));

export const FAQ = () => {
  const classes = useStyles();
  const linkEmail = () => window.location.href = 'mailto:harityas.wiyoga@fs-ip.us';
  const linkWeb = () => window.location.href = 'https://www.oneip.app';

  return (
    <>
      <Header link="/settings" />
      <div className={classes.root}>
        <h5 className="text-center my-4">
          FAQ
        </h5>
        <p>
          <b className={classes.fontQ}>What is OneIP and how does it works?</b> <br />
          OneIP app simplifies our ability to contact each other. Available 24/7 from any phone, tablet, or mobile device, the OneIP app allows you to access important information on the go, including contact information, news, documents, and key dates. It is a cloud-based web application, accessible from anywhere. 
        </p>
        <p>
          <b className={classes.fontQ}>How can I add OneIP to my home screen on my phone?</b> <br />
          iOS:
          <ul>
            <li>Open up the <span className={classes.linkEmail} onClick={linkWeb}>https://www.oneip.app</span> on your iOS device.</li>
            <li>Tap on the "Share" icon at the bottom. This looks like a square with an arrow pointing upwards.</li>
            <li>Select the "Add to Home Screen" icon.</li>
            <li>Give your Link Button a name.</li>
            <li>Tap on the word "Add" in the upper right hand corner.</li>
            <li>Look for this link button on your Home screen.</li>
          </ul>
          Android:
          <ul>
            <li>Navigate to <span className={classes.linkEmail} onClick={linkWeb}>https://www.oneip.app</span> in your preferred browser. Tap the menu button and tap Add to homescreen.</li>
          </ul>
        </p>
        <p>
          <b className={classes.fontQ}>How do I get an account?</b> <br />
          Please reach out to the administrator at <span className={classes.linkEmail} onClick={linkEmail}>harityas.wiyoga@fs-ip.us.</span>
        </p>
        <p>
          <b className={classes.fontQ}>If I have problem accessing the app, who should I contact?</b> <br />
          Please reach out to the administrator at <span className={classes.linkEmail} onClick={linkEmail}>harityas.wiyoga@fs-ip.us.</span>
        </p>
      </div>
    </>
  )
}
