import React from 'react'
import "./Header.css"
import { useHistory } from "react-router-dom"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

const Header = props => {
  let history = useHistory();
  return (
    <div className="header">
      <div className="text">
        { props.link ?
          <ArrowBackIosIcon className="icon" 
          onClick={() => {
            history.push({
              pathname: props.link
            })}} />
          : null
        }
        <span onClick={() => {
          history.push({
            pathname: '/home'
          })
        }}>
          {process.env.REACT_APP_TITLE}
        </span>
      </div>
    </div>
  )
}

export default Header
