import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { customAxios } from '../../api/customAxios';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker } from "@material-ui/pickers";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import Badge from '@material-ui/core/Badge';
import Fab from '@material-ui/core/Fab';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import moment from 'moment';
import "./Calendars.css";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: '50%',
    top: 30,
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    maxWidth: 600,
    flexGrow: 1,
    width: '90%'
  },
  datePic: {
    width: '100%',
    align: 'center'
  },
  colorDate: {
    color: 'red'
  },
  colorPrimary: {
    backgroundColor: '#00502F',
    '&:hover': {
      backgroundColor: '#416d6b',
      boxShadow: 'none',
    },
  },
  fab: {
    zIndex: 999,
    position: 'fixed',
    bottom: 15,
    right: 25
  }
}));

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#00502F",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: "#00502F",
        selectColor: "#00502F",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#00502F",
        '&:hover': {
          backgroundColor: "#00502F",
        }
      },
      current: {
        color: "#00502F",
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        maxWidth: 600
      },
    }      
  },
});

export const Calendars = () => {
  const classes = useStyles();
  const [loadData, setLoadData] = useState(true);
  const [arrDetail, setArrDetail] = useState([]);
  const [arrData, setArrData] = useState([]);
  const [date, setDate] = useState(moment().format());
  const [loadNewItems, setLoadNewItems] = useState(true);
  const [newItems, setNewItems] = useState([]);
  const [highlight, setHighlight] = useState(null);
  const changeDate = data => {
    const filter = arrData.filter(a => moment(a.waktu, 'YYYY-MM-DD').isSame(data, 'date') )
    setArrDetail(filter)
    setDate(data)
  }

  useEffect(()=>{
    if(loadData){
      customAxios('/calendars').then(response=>{
        setArrData(response.data.data);
        const detail = response.data.data.filter(a => moment(a.waktu, 'YYYY-MM-DD').isSame(moment().format(), 'date') );
        setArrDetail(detail);
      }).catch(err=>console.log(err.message))
      setLoadData(false);
    }
  },[loadData])

  useEffect(()=>{
    if(loadNewItems){
      const users = JSON.parse(localStorage.getItem('users'));
      customAxios(`/notifikasi/calendar/${users.id}`)
      .then(response=>{
        if(response.status === 200){
          setNewItems([]);
          response.data.data.map(dt => {
            if (dt.flagid === 0 && dt.idn !== null){
              return setNewItems(newItems => [...newItems, {idn: dt.idn}])
            }
            return null;
          })
          setLoadNewItems(false)
        }
      })
    }
  }, [loadNewItems])

  const onDeleteItems = val => {
    const users = JSON.parse(localStorage.getItem('users'));
    customAxios.post('/notifikasi/resetnotifikasi', {
      category: 'calendar',
      id: users.id,
      idn: val
    })
      .then(() => setLoadNewItems(true))
      .catch(error => console.log(error.message))
  };

  const getNewDate = date => {
    customAxios(`/calendar/${date}`)
      .then(response => {
        const getDate = moment().format(response.data.data.waktu);
        setDate(getDate);
        setHighlight(response.data.data.id_calendar);
      })
      .catch(error => console.log(error.message));
  }

  const onScroll = () => {
    getNewDate(newItems[0].idn);
    onDeleteItems(newItems[0].idn);
  }

  useEffect(()=>{
    if(highlight !== null){
      const timer = () => setTimeout(() => setHighlight(null), 2000);
      const timerId = timer();
      return () => {
        clearTimeout(timerId);
      };
    }
  },[highlight])

  return (
    <>
      <Header link="/home" />
      
      <div style={{
          margin: 'auto',
          maxWidth: 600,
          flexGrow: 1,
          width: '90%'
        }}
        className={highlight !== null ? 'blink' : ''}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={materialTheme} className="text-center">
          <DatePicker
            className={classes.datePic}
            autoOk
            variant="static"
            openTo="date"
            value={date}
            onChange={changeDate}
            InputProps={{ className: classes.colorDate }}
            renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
              const loopData = arrData.map(dt => moment(dt.waktu).format('DDMMYYYY'));
              const isSelected = isInCurrentMonth && loopData.includes(moment(day).format('DDMMYYYY'));
    
              // You can also use our internal <Day /> component
              if(isSelected){
                return <StyledBadge overlap="circular" variant="dot" color="primary">
                  {dayComponent}
                </StyledBadge> 
              }else{
                return <div>
                  {dayComponent}
                </div> 
              }
            }}
          />
        </ThemeProvider>
        </MuiPickersUtilsProvider>
        <div className="container-calendar-list my-3">
          {
            arrDetail.length > 0 ?
            arrDetail.map(data => (
              <div key={data.id} className="calendars-list mb-3 px-3 py-2">
                <div className="row align-items-center">
                  <div className="col-12">
                    <h6 className="calendar-title">
                      {moment(data.waktu).format('dddd, MMMM D YYYY')}
                    </h6>
                    <b>Event : {data.notes}</b>
                    <div className="mt-2" style={{ color: '#00502F' }}>
                      {moment(data.waktu).format('LT')}
                    </div>
                  </div>
                </div>
              </div>
            )) :
            <div className="calendars-list mb-3 px-3 py-2">
              <div className="row align-items-center">
                <div className="col-12">
                  <h6 className="calendar-title">
                    There are no events planned for {moment(date).format('D MMMM  YYYY')}
                  </h6>
                </div>
              </div>
            </div>
          }
        </div>

        {
          newItems.length > 0 ?
          <div onClick={onScroll} className={classes.fab}>
            <Badge badgeContent={newItems.length} color="secondary">
              <Fab color="primary" aria-label="add" className={classes.colorPrimary}>
                <NotificationsActiveIcon fontSize='large' />
              </Fab>
            </Badge>
          </div> : null
        }
      </div>
    </>
  )
}

export default Calendars
