import React from 'react';
import { Row, Col } from 'react-bootstrap';
import "./MenuHome.css";
import { useHistory } from "react-router-dom";
import ItemMenu from './ItemMenu';

const MenuHome = () => {
  const users = JSON.parse(localStorage.getItem('users'));
  let history = useHistory();
  const linkMenu = data => {
    history.push({
      pathname: data
    })
  }
  return (
    <div className="menu">
      <Row className="menu-row justify-content-md-center">
        <Col className="cursor-pointer" xs={4} sm={2} onClick={() => linkMenu("/contacts")}>
          <ItemMenu linkBadge={`/notifikasi/contact/${users.id}`} linkImage="/media/img/menu-contact.png" />
          <div>CONTACTS</div>
        </Col>
        <Col className="cursor-pointer" xs={4} sm={2} onClick={() => linkMenu("/documents")}>
          <ItemMenu linkBadge={`/notifikasi/document/${users.id}`} linkImage="/media/img/menu-document.png" />
          <div>DOCUMENTS</div>
        </Col>
        <Col className="cursor-pointer" xs={4} sm={2} onClick={() => linkMenu("/calendar")}>
          <ItemMenu linkBadge={`/notifikasi/calendar/${users.id}`} linkImage="/media/img/menu-calendar.png" />
          <div>CALENDAR</div>
        </Col>
        <Col className="cursor-pointer" xs={4} sm={2} onClick={() => linkMenu("/news")}>
          <ItemMenu linkBadge={`/notifikasi/news/${users.id}`} linkImage="/media/img/menu-news.png" />
          <div>NEWS</div>
        </Col>
        <Col className="cursor-pointer" xs={4} sm={2} onClick={() => linkMenu("/health-and-safety")}>
          <ItemMenu linkBadge={`/notifikasi/Health and Safety/${users.id}`} linkImage="/media/img/menu-health.png" />
          <div>HEALTH AND SAFETY</div>
        </Col>
        <Col className="cursor-pointer" xs={4} sm={2} onClick={() => linkMenu("/harassment-response")}>
          <ItemMenu linkBadge={`/notifikasi/Harassment Response/${users.id}`} linkImage="/media/img/menu-harassment.png" />
          <div>HARASSMENT RESPONSE</div>
        </Col>
      </Row>
    </div>
  )
}

export default MenuHome
