import React, { useState, useEffect } from 'react';
import "./NewsHome.css";
import Button from '@material-ui/core/Button';
import { Image, Row, Col } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { customAxios } from '../api/customAxios';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const urlImage = process.env.REACT_APP_UPLOADS + 'news/';

const NewsHome = () => {
  let history = useHistory();
  const [loadData, setLoadData] = useState(true);
  const [dataNews, setDataNews] = useState([]);
  const [dataCalendar, setDataCalendar] = useState([]);

  const linkNews = data => {
    history.push({
      pathname: data
    })
  }

  useEffect(()=>{
    if(loadData){
      customAxios('/news').then(response=>{
        if (response.status === 200) {
          setDataNews(response.data.data)
        }
      }).catch(err => console.log(err.message))

      customAxios('/calendars').then(response=>{
        if (response.status === 200) {
          const filterData = response.data.data.filter( dt => {
            if (moment(dt.waktu).isAfter(moment().format('YYYY-MM-DD')) > 0){
              return dt
            }
            return false
          });
          const sortData = filterData.sort((a, b) => moment(a.waktu).diff(b.waktu) );
          setDataCalendar(sortData);
        }
      }).catch(err => console.log(err.message))
    }
    setLoadData(false)
  },[loadData])

  return (
    <>
     <div className="news-home mt-3">
        <span className="text">
          <b>Upcoming Events</b>
        </span>
        <Button className="btn" variant="outlined" size="small" color="primary"
          onClick={() => linkNews("/calendar")}>
          See All
        </Button>
        <div className="content">
          {
            dataCalendar.length > 0  ?
            <Row>
              <Col onClick={() => linkNews("/calendar")} className="calendar-text padr-0 text-center" xs={4} md={4}>
                <h5>{moment(dataCalendar[0].waktu).format('D MMM ')}</h5>
              </Col>
              <Col className="padx-0" xs={1} md={1}>
                <ArrowRightIcon className="icon" />
              </Col>
              <Col className="calendar-text padx-0" xs={7} md={7}>
                <span className="text-2">
                  <b>
                    {dataCalendar[0].notes.substring(0, 18)}...
                  </b>
                </span>
              </Col>
            </Row> : 
            <span className="text">
              <b>No Upcoming Event!</b>
            </span>
          }
        </div>
      </div>
      <div className="news-home mt-3">
        <span className="text">
          <b>News</b>
        </span>
        <Button className="btn" variant="outlined" size="small" color="primary"
          onClick={() => linkNews("/news")}>
          See All
        </Button>
        <div className="content">
          {
            dataNews.length > 0 ?
            dataNews.filter((item, idx) => idx < 2).map((data) => (
            <Row key={data.id_news} className="mb-2">
              <Col xs={4} md={4}>
                <Image 
                  width="100%" 
                  style={{ cursor: 'pointer' }}
                  onClick={() => linkNews("/news/detail/" + data.id_news)}
                  src={ data.images ? urlImage + data.images : process.env.PUBLIC_URL + "/media/news/post-1.jpeg" } />
              </Col>
              <Col xs={8} md={8}>
                <p className="text-2">
                  <b onClick={() => linkNews("/news/detail/" + data.id_news)}>
                    {data.title.substring(0, 35)}...
                  </b>
                  <br />
                  <span className="text-3">
                    {moment(data.created_at.date).format('dddd, MMMM D YYYY')}
                  </span>
                </p>
              </Col>
            </Row> 
            )) : 
            <span className="text">
              <b>No Upcoming News!</b>
            </span>
          }
        </div>
      </div>
    </>
  )
}

export default NewsHome
