import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Header from '../../components/Header';
import { makeStyles } from '@material-ui/core/styles';
import { customAxios } from '../../api/customAxios';
import moment from 'moment';
import parse from 'html-react-parser';
import { Card } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    maxWidth: 600,
    flexGrow: 1,
    width: '90%'
  },
  date: {
    textAlign: 'right', 
    fontSize: '13px'
  },
  card: {
    border: 0
  },
  cardTitle: {
    fontSize: '15px'
  },
  cardBody: {
    paddingRight: 0,
    paddingLeft: 0
  },
  cardDesc: {
    fontSize: '13px'
  }
}));

export const DocumentDetail = () => {
  const classes = useStyles();
  let { idDetail } = useParams();
  const [loadData, setLoadData] = useState(true)
  const [arrData, setArrData] = useState(null)
  const [notFound, setNotFound] = useState(0)

  useEffect(() => {
    if(loadData){
      customAxios(`/document-detail/${idDetail}`).then(response => {
        if (response.status === 200) {
          setArrData(response.data.data)
          setNotFound(0)
        }
      }).catch(err => {
        console.log(err.message)
        setArrData(null)
        setNotFound(1)
      })
      setLoadData(false)
    }
  }, [loadData, idDetail])

  return (
    <>
      <Header link="/documents" />
      {
        arrData ? (
          <div className={classes.root}>
            <div className={classes.date}>
              {moment(arrData.created_at).format('dddd, MMMM D YYYY / h:mm A')}
            </div>
            <Card className={classes.card}>
              <Card.Body className={classes.cardBody}>
                <h6 className={classes.cardTitle}>
                  {arrData.name_doc}
                </h6>
                <div className={classes.cardDesc}>
                  {arrData.content ? parse(arrData.content) : null}
                </div>
              </Card.Body>
            </Card>
          </div>
        ) : notFound === 1 ? (
          <h1 className="text-center">NOT FOUND</h1>
        ) : null 
      }
    </>
  )
}
