import React from 'react';
import Header from '../components/Header';

const Error404 = () => {
  return (
    <>
      <Header />
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '80vh',
      }}>
        <h1 style={{ fontSize: 40, marginRight: 8 }}>404</h1>
        <span style={{ fontSize: 14 }}>NOT FOUND!</span>
      </div>
    </>
  )
}

export default Error404
