import React from 'react'
import { Image, Row, Col } from 'react-bootstrap';
import "./BotNav.css";
import { useHistory, useLocation } from "react-router-dom";

const BotNav = () => {
  let history = useHistory();
  let location = useLocation();
  const menuLink = data => {
    history.push({
      pathname: data
    })
  }
  const arrMenu = [
    {
      link: "/home",
      name: "HOME",
      image: "/media/img/menu-home.png"
    },
    {
      link: "/contact-admin",
      name: "CONTACT ADMIN",
      image: "/media/img/menu-contact-admin.png"
    },
    {
      link: "/settings",
      name: "SETTINGS",
      image: "/media/img/menu-settings.png"
    }
  ]

  return (
    <div className="bot-nav">
      <Row className="bot-row justify-content-md-center">
        {
          arrMenu.map((val, i)=>(
            <Col className="cursor-pointer" key={i} xs={4} md={4} onClick={() => menuLink(val.link)}>
              {
                location.pathname === val.link ? 
                  <div className="active"></div>
                : null
              }
              <Image className="img-nav" src={process.env.PUBLIC_URL + val.image} />
              <div>{val.name}</div>
            </Col>
          ))
        }
      </Row>
    </div>
  )
}

export default BotNav
