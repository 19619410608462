import React from 'react';
import Header from '../../components/Header';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    maxWidth: 600,
    flexGrow: 1,
    width: '90%',
    wordWrap: 'break-word',
  }
}));

export const AboutApps = () => {
  const classes = useStyles();
  return (
    <>
      <Header link="/settings" />
      <div className={classes.root}>
        <h5 className="text-center my-4">
          About Apps
        </h5>
        <p>
          The OneIP app is live! It can be accessed from your smartphone, tablet, or web browser. The OneIP app allows easy access to information, especially while traveling. The app stores IP’s staff directory and has additional functionality including a calendar of federal and international holidays; timesheet reminders; news and updates; SOS emergency services information; and tap to call features for phone and WhatsApp.
        </p>
        <p>
          More information can be found on the internal website: 
          <br />
          <a href="https://internal.internationalprograms.us/knowledge-management/systems/oneip-app/" rel="noreferrer" target="_blank">https://internal.internationalprograms.us/knowledge-management/systems/oneip-app/</a>
        </p>
      </div>
    </>
  )
}
