import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import parse from 'html-react-parser';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PhoneIcon from '@material-ui/icons/Phone';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { customAxios } from '../../api/customAxios';
import { useParams } from 'react-router-dom';
import './ContactDetail.css';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    maxWidth: 600,
    flexGrow: 1,
    width: '90%'
  },
  circle: {
    margin: 'auto',
    width: '150px',
    height: '150px',
    lineHeight: '150px',
    borderRadius: '50%',
    fontSize: '50px',
    textAlign: 'center',
    backgroundColor: 'grey',
    color: '#000'
  },
  name: {
    fontSize: '24px'
  },
  hr: {
    marginBottom: '0 !important',
    marginTop: '0 !important',
    borderTop: '1px solid rgb(0 0 0 / 30%)',
    marginBlockStart: '0 !important',
    marginBlockEnd: '0 !important'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '5px double #00502F',
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 15px',
    justifyContent: 'center',
  },
  btn: {
    width: '125px',
    align: 'center',
    color: '#00502F',
  }
}));

const urlFoto = process.env.REACT_APP_UPLOADS + 'profile/';

export const ContactDetail = (props) => {
  const classes = useStyles();
  const { idDetail } = useParams();
  const initialBioLength = 80;

  const [dataContact, setDataContact] = useState({});
  const [dataProfile, setDataProfile] = useState({ foto: '', bio: '' });
  const [dataUnit, setDataUnit] = useState({ unit: '' });
  const [isModal, setIsModal] = useState(false);
  const [phone, setPhone] = useState('');
  const [bio, setBio] = useState('');
  const [bioLength, setBioLength] = useState(initialBioLength);
  const [bioMore, setBioMore] = useState(false);
  const [errImg, setErrImg] = useState(false);
  const handleShowModal = (data) => {
    setIsModal(true);
    setPhone(data.replace(/(-|[()]|\s|[a-z]|[A-Z]|[.]|[+])/g, ''));
  }
  const handleHideMOdal = () => setIsModal(false);
  const handleMoreBio = () => {
    if (bioLength < 100){
      setBio(dataProfile.bio);
      setBioLength(dataProfile.bio.length);
    }else{
      setBio(dataProfile.bio.substring(0, initialBioLength));
      setBioLength(initialBioLength);
    }
  };
  const handleMouseDownPassword = (event) => event.preventDefault();

  useEffect(() => {
    customAxios(`/contact/${idDetail}`)
      .then(res => setDataContact(res.data.data))
      .catch(err => window.location.href = '/404')
  }, [idDetail])

  useEffect(() => {
    if (dataContact.email) {
      customAxios(`/profile/${dataContact.email}`)
        .then(res => {
          if (res?.data?.data?.bio?.length > 80) {
            setBioMore(true);
          }
          setDataProfile(res?.data?.data ?? '');
          setBio(res?.data?.data?.bio?.substring(0, initialBioLength) ?? '');
          setDataUnit(res?.data?.data2 ?? '');
        })
        .catch(err => console.log(err.message))
    }
  }, [dataContact.email])

  return (
    <>
    <Header link="/contacts" />
    <div className={classes.root}>
      {
          dataContact ? (
            <div className='mb-3'>
              <div className={classes.circle + " mb-3"}>
                {
                  dataProfile.foto && !errImg ? 
                    <img
                      onError={() => {
                        setErrImg(true);
                      }}
                      style={{ width: '100%', height: '100%', zIndex: 20, borderRadius: '50%', verticalAlign: 'initial' }}
                      alt={dataContact.last_name !== null ? `${dataContact.last_name}, ${dataContact.first_name}` : dataContact.first_name}
                      src={urlFoto + encodeURI(dataProfile.foto)} />
                  :
                  (
                    <>
                      {dataContact.first_name ? parse(dataContact.first_name.substring(0, 1)) : null}
                      {dataContact.last_name ? parse(dataContact.last_name.substring(0, 1)) : null}
                    </>
                  )
                }
              </div>
              <div className="mb-2">
                <span className={classes.name}>
                  {
                    dataContact.last_name !== null ? `${dataContact.last_name}, ${dataContact.first_name}`
                    : dataContact.first_name
                  }
                </span>
                <hr className={classes.hr} />
              </div>
              <TextField
                value={dataContact.preferred ?? ""} 
                disabled 
                className="mb-2" 
                label="Preferred Name" 
                fullWidth />
              <TextField
                value={dataContact.title ?? ""} 
                disabled 
                className="mb-2" 
                label="Title" 
                fullWidth />
              <FormControl fullWidth className="mb-2">
                <InputLabel htmlFor="standard-adornment-password">Short Bio</InputLabel>
                <Input
                  disabled
                  id="standard-adornment-password"
                  type="text"
                  value={bio}
                  multiline
                  endAdornment={
                    bioMore ?
                    <InputAdornment position="end">
                      <IconButton
                        style={{cursor:'pointer'}}
                        aria-label="toggle password visibility"
                        onClick={handleMoreBio}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {bio?.length > 80 ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                      </IconButton>
                    </InputAdornment> : null
                  }
                />
              </FormControl>
              <TextField
                value={dataUnit.unit ?? ""} 
                disabled 
                className="mb-2" 
                label="Unit" 
                fullWidth />
              <TextField 
                onClick={(e) => {
                  if(e.target.value === ""){
                    return
                  }
                  return handleShowModal(dataContact.phone)
                }}
                value={dataContact.phone ?? ""}
                readOnly={dataContact.phone ? true : false}
                disabled={dataContact.phone ? false : true}
                className="mb-2" 
                label="Work Phone" 
                fullWidth />
              <TextField
                onClick={(e) => {
                  if(e.target.value === ""){
                    return
                  }
                  return window.location.href = `mailto:${dataContact.email}`
                }}
                value={dataContact.email ?? ""} 
                readOnly={dataContact.email ? true : false}
                disabled={dataContact.email ? false : true}
                className="mb-2" 
                label="Email" 
                fullWidth />
              <TextField 
                onClick={(e) => {
                  if(e.target.value === ""){
                    return
                  }
                  return handleShowModal(dataContact.fs_phone)
                }}
                value={dataContact.fs_phone ?? ""} 
                readOnly={dataContact.fs_phone ? true : false}
                disabled={dataContact.fs_phone ? false : true}
                className="mb-2" 
                label="FS Cell Phone" 
                fullWidth />
              <TextField 
                onClick={(e) => {
                  if(e.target.value === ""){
                    return
                  }
                  return handleShowModal(dataContact.other_phone)
                }}
                value={dataContact.other_phone ?? ""}
                readOnly={dataContact.other_phone ? true : false}
                disabled={dataContact.other_phone ? false : true}
                className="mb-2" 
                label="Other Phone" 
                fullWidth />
              <TextField 
                onClick={(e) => {
                  if(e.target.value === ""){
                    return
                  }
                  return window.location.href = `mailto:${dataContact.other_email}`
                }}
                value={dataContact.other_email ?? ""} 
                readOnly={dataContact.other_email ? true : false}
                disabled={dataContact.other_email ? false : true}
                className="mb-2" 
                label="Other Email" 
                fullWidth />
              <TextField
                value={dataContact.city ?? ""} 
                disabled 
                className="mb-2" 
                label="City" 
                fullWidth />
              <TextField
                value={dataContact.country ?? ""} 
                disabled 
                className="mb-2" 
                label="Country" 
                fullWidth />
              <TextField
                value={dataContact?.state ?? ""} 
                disabled 
                className="mb-2" 
                label="State" 
                fullWidth />
            </div>
          ) : <h1 className="text-center">NOT FOUND</h1>
        }
    </div>

    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isModal}
      onClose={handleHideMOdal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isModal}>
        <div className={classes.paper}>
          <div className="col m-2">
            <div className="text-center">
              <Button onClick={()=> window.location.href = `https://wa.me/${phone}`} startIcon={<WhatsAppIcon />} className={classes.btn} variant="outlined">WhatsApp</Button>
            </div>
          </div>
          <div className="col m-2">
            <div className="text-center">
              <Button onClick={()=> window.location.href = `tel:+${phone}`} startIcon={<PhoneIcon />} className={classes.btn} variant="outlined">Phone</Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  </>
  )
}
