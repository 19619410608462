import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { TextField, Button } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import { useHistory } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import qs from 'qs';

const useStyles = makeStyles({
  container: {
    width: '90%',
    maxWidth: '600px',
    margin: '50px auto'
  },
  text: {
    color: '#666666',
    fontSize: '24px'
  },
  logo: {
    width: '80px',
    borderRadius: 8
  }
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#00502F'),
    fontSize: '22px',
    backgroundColor: '#00502F',
    '&:hover': {
      backgroundColor: '#15716d',
    },
  },
}))(Button);

const PasswordResetChange = () => {
  let { tokenLink } = useParams();
  const classes = useStyles();
  let history = useHistory();
  const [disableInput, setDisableInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [typeMsg, setTypeMsg] = useState('success');
  const token = localStorage.getItem("token");

  useEffect(() => {
    if(token !== null){
      history.push({
        pathname: '/home'
      })
    }
  }, [token, history])

  const validationSchema = yup.object({
    password: yup
      .string('Enter your password')
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required'),
    confirm_password: yup
      .string('Confirm your password')
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required')
      .when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: yup.string().oneOf(
          [yup.ref("password")],
          "Both password need to be the same"
        )
      }),
  });

  const enableLoading = () => {
    setLoading(true);
  };
  
  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading()
      setTimeout(() => {
        axios({
          method: "POST",
          headers: {
            'X-ApiKey': process.env.REACT_APP_KEY,
            'Content-Type':'application/x-www-form-urlencoded;charset=utf-8'
          },
          url: `${process.env.REACT_APP_API}/password/reset`, 
          data: qs.stringify({
            token: tokenLink,
            password_baru: values.password,
            password_baru_confirm: values.confirm_password 
          }),
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              setTypeMsg('success')
              setStatus('Password changed successfully ! Please Login.')
              setDisableInput(true)
              disableLoading()
              return
            }
          }
          setTypeMsg('error')
          setStatus(response.data.message)
          disableLoading()
        })
        .catch(error => {
          localStorage.clear()
          setTypeMsg('error')
          if (error.response !== undefined) {
            setStatus(error.response.data.message)
          }else{
            setStatus(error.message)
          }
          disableLoading()
        })
      }, 1000)
      setSubmitting(false)
    },
  });

  return (
    <>
      <div className={classes.container}>
        <div className="text-center mb-3">
          <img className={classes.logo} src={process.env.PUBLIC_URL + "/media/img/logo-v1.png"} alt="Logo OneIP" />
        </div>

        <form onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(e); }} >
          {formik.status ? (          
            <Alert className="mb-3" severity={typeMsg}>{formik.status}</Alert>
          ) : ""}
          <p hidden={disableInput} className="text-center mb-3">
            <b className={classes.text}>Reset Password</b>
          </p>
          <TextField
            id="password"
            {...formik.getFieldProps("password")}
            name="password"
            className="mb-3"
            type="password"
            placeholder="new password"
            label="" 
            variant="outlined" 
            fullWidth
            hidden={disableInput}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              startAdornment: 
              <InputAdornment position="start">
                <VpnKeyOutlinedIcon color="disabled" />
              </InputAdornment>,
            }} />
          <TextField
            id="confirm_password"
            {...formik.getFieldProps("confirm_password")}
            name="confirm_password"
            className="mb-3"
            type="password"
            placeholder="confirm password"
            label="" 
            variant="outlined" 
            fullWidth
            hidden={disableInput}
            value={formik.values.confirm_password}
            onChange={formik.handleChange}
            error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
            helperText={formik.touched.confirm_password && formik.errors.confirm_password}
            InputProps={{
              startAdornment: 
              <InputAdornment position="start">
                <VpnKeyOutlinedIcon color="disabled" />
              </InputAdornment>,
            }} />
          <ColorButton hidden={disableInput} disabled={formik.isSubmitting} type="submit" className="mt-5" fullWidth variant="contained" color="primary">
            Reset Password
          </ColorButton>
          <div 
            style={{ color: '#00502F', fontSize: '16px', cursor: 'pointer' }} 
            className="text-center"
            onClick={() => {history.push({
              pathname: '/'
            })}}
            hidden={!disableInput}>
              Back To Login
          </div>
          {loading && <LinearProgress />}
        </form>
      </div>
    </>
  )
}

export default PasswordResetChange
