import React, { useCallback, useEffect, useState } from 'react';
import Header from '../../components/Header';
import { Card } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from "react-router-dom";
import { customAxios } from '../../api/customAxios';
import moment from 'moment';
import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    marginBottom: 16,
    maxWidth: 600,
    flexGrow: 1,
    width: '90%'
  },
  cardTitle: {
    fontSize: '15px',
  },
  cardDate: {
    fontSize: '11px', 
    color: '#00502F'
  },
  readMore: {
    fontSize: '13px',
    color: '#00502F',
    cursor: 'pointer'
  }
}));

const urlImage = process.env.REACT_APP_UPLOADS + 'news/';

const News = () => {
  const classes = useStyles();
  let history = useHistory();
  const linkNews = data => {
    history.push({
      pathname: `/news/detail/${data}`
    })
  }
  const [loadData, setLoadData] = useState(true);
  const [arrData, setArrData] = useState([]);
  const [textSearch, setTextSearch] = useState("");
  const [loadNewItems, setLoadNewItems] = useState(true);
  const [newItems, setNewItems] = useState([]);

  useEffect(() => {
    if(loadData){
      customAxios('/news').then(response => {
        if (response.status === 200) {
          setArrData(response.data.data)
        }
      }).catch(err => console.log(err.message))
      setLoadData(false)
    }
  }, [loadData])

  useEffect(()=>{
    if(loadNewItems){
      const users = JSON.parse(localStorage.getItem('users'));
      customAxios(`/notifikasi/news/${users.id}`)
      .then(response=>{
        if(response.status === 200){
          setNewItems([]);
          response.data.data.map(dt => {
            if (dt.flagid === 0 && dt.idn !== null){
              return setNewItems(newItems => [...newItems, dt.idn])
            }
            return null;
          })
          setLoadNewItems(false)
        }
      })
    }
  }, [loadNewItems])

  const onDeleteItems = useCallback(
    () => {
      newItems.map(data => {
        const users = JSON.parse(localStorage.getItem('users'));
        return customAxios.post('/notifikasi/resetnotifikasi', {
          category: 'news',
          id: users.id,
          idn: data
        })
          .then(() => {
            setLoadNewItems(true);
            setNewItems([]);
          })
          .catch(error => console.log(error.message))
      });
    },
    [newItems]
  );

  useEffect(()=>{
    if(newItems.length > 0){
      const timer = () => setTimeout(() => onDeleteItems(), 2000);
      const timerId = timer();
      return () => {
        clearTimeout(timerId);
      };
    }
  },[newItems, onDeleteItems])

  return (
    <>
      <Header link="/home" />
      <div className={classes.root}>
        <TextField
          className="mb-3"
          type="search"
          placeholder="Search News"
          label="" 
          variant="outlined" 
          fullWidth
          onChange={e => setTextSearch(e.target.value.toLowerCase())}
          InputProps={{
            startAdornment: 
            <InputAdornment position="start">
              <SearchIcon color="disabled" />
            </InputAdornment>,
          }} />
        {
          arrData.filter(item => item.title.toLowerCase().includes(textSearch)).map((data) => (
            <Card key={data.id_news}
              className={newItems.length > 0 && newItems.includes(data.id_news) ? 'contact-highlight mb-3' : 'mb-3'}>
              <Card.Img style={{ cursor: 'pointer' }} variant="top" 
                src={ data.images ? urlImage + data.images : process.env.PUBLIC_URL + "/media/news/post-1.jpeg" } 
                onClick={() => linkNews(data.id_news)} />
              <Card.Body>
                <div className={classes.cardTitle}>
                  <b style={{ cursor: 'pointer' }} onClick={() => linkNews(data.id_news)}>{data.title ? data.title : null}</b>
                </div>
                <span className={classes.cardDate}>
                  {moment(data.created_at.date).format('dddd, MMMM D YYYY')}
                </span>
                <div >{data.short_content ? parse(data.short_content.substring(0, 55)) : null} ...
                  <span className={classes.readMore}
                    onClick={() => linkNews(data.id_news)}>
                    <b>(READ MORE)</b>
                  </span>
                </div>
              </Card.Body>
            </Card>
          ))
        }
      </div>
    </>
  )
}

export default News
