import React, { useEffect, useState, useRef } from 'react';
import Header from '../../components/Header';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import BackupIcon from '@material-ui/icons/Backup';
import SaveIcon from '@material-ui/icons/Save';
import { MenuItem, Snackbar, Modal, Menu, Typography } from '@material-ui/core';
import { customAxios } from '../../api/customAxios';
import MuiAlert from '@material-ui/lab/Alert';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './UserProfile.css';
import axios from 'axios';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    maxWidth: 600,
    flexGrow: 1,
    width: '90%'
  },
  circle: {
    position: 'relative',
    margin: 'auto',
    width: '150px',
    height: '150px',
    lineHeight: '150px',
    borderRadius: '50%',
    fontSize: '50px',
    textAlign: 'center',
    backgroundColor: 'grey',
    color: '#000',
    cursor: 'pointer',
  },
  btnCamera: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 40,
    height: 40,
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor: '#00502F',
    '&:hover': {
      backgroundColor: '#096763',
      transition: 'background-color 0.5s ease-out',
    },
  },
  btnDelete: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 40,
    height: 40,
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor: '#00502F',
    '&:hover': {
      backgroundColor: '#a00139',
      transition: 'background-color 0.5s ease-out',
    },
  },
  flexDelete: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconDelete: {
    fontSize: 24,
    margin: 8,
    color: '#FFF'
  },
  flexCamera: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconCamera: {
    fontSize: 24,
    margin: 8,
    color: '#FFF'
  },
  name: {
    fontSize: '24px'
  },
  hr: {
    marginBottom: '0 !important',
    marginTop : '0 !important',
    borderTop: '1px solid rgb(0 0 0 / 30%)',
    marginBlockStart: '0 !important',
    marginBlockEnd: '0 !important'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '5px double #00502F',
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 15px',
    justifyContent: 'center',
  },
  btn: {
    width: '125px',
    align: 'center',
    color: '#00502F',
  }
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#00502F'),
    fontSize: '22px',
    backgroundColor: '#00502F',
    '&:hover': {
      backgroundColor: '#15716d',
    },
  },
}))(Button);

export const UserProfil = () => {
  const classes = useStyles();
  const fileInput = useRef(null);
  const users = JSON.parse(localStorage.getItem('users'));
  const [open, setOpen] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);
  const [dataProfile, setDataProfile] = useState({});
  const [lengthShort, setLengthShort] = useState(0);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertStatus, setAlertStatus] = useState('');
  const [alert, setAlert] = useState(false);
  const [bio, setBio] = useState('');
  const [unit, setUnit] = useState(users.region);
  const [email, setEmail] = useState('');
  const [foto, setFoto] = useState('');
  const [showFoto, setShowFoto] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [phone, setPhone] = useState('');
  const [title, setTitle] = useState('');
  const [country, setCountry] = useState([]);
  const [selectCountry, setSelectCountry] = useState('');
  const [city, setCity] = useState('');
  const [jalan, setJalan] = useState('');

  const menuShow = (event) => setAnchorEl(event.currentTarget);
  const menuClose = () => setAnchorEl(null);
  const handleUpload = () => {
    fileInput.current.click();
    menuClose();
  }
  const handleDelPhoto = () => {
    setShowFoto(null);
    setFoto('DELETE');
    menuClose();
  }

  // first render
  useEffect(() => {
    const users = JSON.parse(localStorage.getItem('users'));
    setEmail(users.email);
    customAxios(`/profile/${users.email}`)
      .then(res => {
        setDataProfile(res?.data?.data);
        setUnit(res?.data?.data2?.unit);
        setBio(res?.data?.data?.bio || '');
        if (res.data.data.foto){
          setShowFoto(process.env.REACT_APP_UPLOADS + 'profile/' + encodeURI(res.data.data.foto));
        }
        setLengthShort(res?.data?.data?.bio?.length);
        setPhone(res?.data?.data?.phone || '');
        setTitle(res?.data?.data?.title || '');
        setSelectCountry(res?.data?.data?.country || '');
        setCity(res?.data?.data?.city || '');
        setJalan(res?.data?.data?.state || '');
      })
      .catch(err => console.log(err.message) )

    axios.get('https://restcountries.com/v3.1/all?fields=name')
      .then(res => {
        console.log('tes render res', res.data);
        const mapping = res.data.map(item => item?.name?.common);
        console.log('tes render map', mapping);
        setCountry(mapping?.sort());
      })
      .catch(err => console.log(err.message) )
  }, [])

  useEffect(() => {
    if (loadData){
      const users = JSON.parse(localStorage.getItem('users'));
      customAxios(`/profile/${users.email}`);
      setTimeout(() => window.location.reload(), 3000);
    }
  }, [loadData])

  const handleClose = (event, reason) => {
    window.location.reload();
    if (reason === 'clickaway') {
      return;
    }
    setAlert(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("foto", foto);
    formData.append("email", email);
    formData.append("bio", bio ?? '');
    formData.append("unit", unit);
    formData.append("phone", phone);
    formData.append("title", title);
    formData.append("country", selectCountry);
    formData.append("city", city);
    formData.append("state", jalan);

    // check if online or offline
    if ('serviceWorker' in navigator) {
      var condition = navigator.onLine ? "online" : "offline";
      if(condition){
        customAxios({
          url: '/profile/change',
          method: 'post',
          data: formData,
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
          .then(res => {
            if (res.status < 300) {
              setLoadData(true);
              setHideHeader(true);
              setAlert(true);
              setAlertStatus('success');
              setAlertMessage('Data User Profile updated successfully!')
            }else{
              setAlert(true);
              setAlertStatus('error');
              setAlertMessage('Something went wrong on the server!')
            }
          })
          .catch(err => {
            setAlert(true);
            setAlertStatus('error');
            setAlertMessage('Something went wrong on the server!')
          })
      }else{
        localStorage.setItem("updateUserProfile", JSON.stringify({ foto, email, bio, unit}));
        setAlert(true);
        setAlertStatus('error');
        setAlertMessage(`You're offline, the data is updated immediately if you are online!`)
      }
    }

  }

  return (
    <>
    <Snackbar open={alert} autoHideDuration={3000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={alertStatus}>
        {alertMessage}
      </Alert>
    </Snackbar>
    <Header link={hideHeader ? null : '/settings'} />
    <div className={classes.root}>
      <form className="mb-3" onSubmit={handleSubmit}>
        <h5 className="text-center my-4">
          USER PROFILE
        </h5>
        <div className={classes.circle + " mb-3"}>
          <div onClick={menuShow} className={classes.btnDelete}>
            <div className={classes.flexDelete}>
              <CameraAltIcon className={classes.iconDelete} />
            </div>
          </div>
          <img
            onClick={() => setOpen(true)}
            className="user-profile-img"
            src={showFoto ?? process.env.PUBLIC_URL + '/media/img/default-user.jpeg'}
            alt={dataProfile.first_name + ' ' + dataProfile.last_name}
            />
        </div>
        <TextField 
          className="mb-3" 
          label="Title" 
          name="title"
          value={title ?? ''}
          fullWidth
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField 
          className="mb-3" 
          label="Short Bio" 
          name="bio"
          value={bio ?? ''}
          multiline
          rows={3}
          fullWidth
          inputProps={{ maxLength: 2000 }}
          onChange={(e) => {
            setLengthShort(e.target.value.length);
            setBio(e.target.value);
          }}
          helperText={`${lengthShort} of 2000 characters`}
        />
        <TextField
          id="standard-select-currency"
          select
          className="mb-3"
          label="Select Unit"
          value={unit}
          name="unit"
          onChange={(e) => setUnit(e.target.value)}
          fullWidth
        >
          <MenuItem value="AP">Asia Pacific</MenuItem>
          <MenuItem value="AME">Africa and Middle East</MenuItem>
          <MenuItem value="LACC">Latin America, Carribean, and Canada</MenuItem>
          <MenuItem value="EE">Europe and Eurasia</MenuItem>
          <MenuItem value="DO">Director’s Office</MenuItem>
          <MenuItem value="DASP">Disaster Assistance Support Program</MenuItem>
          <MenuItem value="GP">Global Programs</MenuItem>
          <MenuItem value="MS">Migratory Species</MenuItem>
          <MenuItem value="OP">Operations</MenuItem>
          <MenuItem value="UOP">Urban Outreach and Partnerships</MenuItem>
          <MenuItem value ="WPT">Trade</MenuItem>
          <MenuItem value ="WEIL">Workplace Environment, Information, and Learning</MenuItem>
          <MenuItem value ="FS">FSIF</MenuItem>
        </TextField>
        <PhoneInput
          className="mb-3"
          country={'us'}
          value={phone}
          onChange={phone => setPhone(phone)} />
        <TextField
          id="country"
          select
          className="mb-3"
          label="Select Country"
          value={selectCountry}
          name="country"
          onChange={(e) => setSelectCountry(e.target.value)}
          fullWidth
        >
          {
            country?.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))
          }
        </TextField>
        <TextField 
          className="mb-3" 
          label="City" 
          name="city"
          value={city ?? ''}
          fullWidth
          onChange={(e) => setCity(e.target.value)}
        />
        <TextField 
          label="State" 
          name="state"
          value={jalan ?? ''}
          fullWidth
          onChange={(e) => setJalan(e.target.value)}
        />
        <ColorButton type="submit" className="mt-4" fullWidth variant="contained" color="primary" startIcon={<SaveIcon />}>
          SAVE DATA
        </ColorButton>
      </form>
    </div>

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
      <img
        style={{maxWidth:600,width:'90%'}}
        src={showFoto ?? process.env.PUBLIC_URL + '/media/img/default-user.jpeg'}
        alt={dataProfile.first_name + ' ' + dataProfile.last_name} />
    </Modal>

    <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={menuClose}
      >
        <MenuItem onClick={handleUpload}>
          <BackupIcon fontSize="small" color="primary" className="mr-3" />
          <Typography variant="inherit">Upload Photo</Typography>
        </MenuItem>
        <MenuItem onClick={handleDelPhoto}>
          <DeleteForeverIcon fontSize="small" color="error" className="mr-3" />
          <Typography variant="inherit">Remove Photo</Typography>
        </MenuItem>
      </Menu>

    <input
      hidden
      name="foto"
      onChange={(e) => {
        setShowFoto(URL.createObjectURL(e.target.files[0]));
        setFoto(e.target.files[0]);
      }}
      accept="image/*"
      ref={fileInput}
      type="file"
    />
  </>
  )
}
