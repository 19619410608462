import axios from 'axios';

// Create axios
const customAxios = axios.create({
  baseURL: process.env.REACT_APP_API
});

customAxios.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token");
    const users = localStorage.getItem('users');
    if (config.url !== '/auth') {
      if(token === undefined || token === null || users === undefined || users === null) {
        localStorage.clear();
        window.location.href = '/';
      }
      config.headers = {'X-ApiKey': token}
    }
    if(config.method.toLowerCase() === 'get' || config.method.toLowerCase() === 'post'){
      config.headers = {
        'X-ApiKey': token
      };
    }
  
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

customAxios.interceptors.response.use(res => {
  return res;
},(error)=>{
  if(error.response.status === 401){
    localStorage.clear();
    window.location.href = '/';
  }
  return Promise.reject(error);
});

export { customAxios };