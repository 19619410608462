import React, { useState, useEffect } from 'react'
import { TextField, Button } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import { useHistory } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import qs from 'qs';

const useStyles = makeStyles({
  container: {
    width: '90%',
    maxWidth: '600px',
    margin: '50px auto'
  },
  text: {
    color: '#666666',
    fontSize: '24px'
  },
  logo: {
    width: '80px',
    borderRadius: 8
  }
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#00502F'),
    fontSize: '22px',
    backgroundColor: '#00502F',
    '&:hover': {
      backgroundColor: '#15716d',
    },
  },
}))(Button);

const Login = () => {
  const classes = useStyles();
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [typeMsg, setTypeMsg] = useState('success');
  const token = localStorage.getItem("token");

  useEffect(() => {
    if(token !== null){
      history.push({
        pathname: '/home'
      })
    }
  }, [token, history])

  const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup
      .string('Enter your password')
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required'),
  });

  const enableLoading = () => {
    setLoading(true);
  };
  
  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading()
      setTimeout(() => {
        axios({
          method: "POST",
          headers: {
            'X-ApiKey': process.env.REACT_APP_KEY,
            'Content-Type':'application/x-www-form-urlencoded;charset=utf-8'
          },
          url: `${process.env.REACT_APP_API}/auth`, 
          data: qs.stringify(values),
        })
        .then(response => {
          if (response.status === 200 && response?.data?.data?.type.toLowerCase() === 'apps') {
            window.location.reload()
            localStorage.setItem("token", process.env.REACT_APP_KEY);
            localStorage.setItem('users', JSON.stringify(response.data.data));
            disableLoading()
            setTypeMsg('success')
            setStatus("Login Success")
          }else{
            setTypeMsg('error')
            setStatus("Login Failed")
            disableLoading()
          }
        })
        .catch(error => {
          localStorage.clear()
          setTypeMsg('error')
          if (error.response !== undefined) {
            setStatus(error.response.data.message)
          }else{
            setStatus(error.message)
          }
          disableLoading()
        })
      }, 1000)
      setSubmitting(false)
    },
  });

  return (
    <>
      <div className={classes.container}>
        <div className="text-center mb-3">
          <img className={classes.logo} src={process.env.PUBLIC_URL + "/media/img/logo-v1.png"} alt="Logo OneIP" />
        </div>

        <form onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(e); }} >
          {formik.status ? (          
            <Alert className="mb-3" severity={typeMsg}>{formik.status}</Alert>
          ) : ""}
          <p className="text-center mb-3">
            <b className={classes.text}>LOGIN</b>
          </p>
          <TextField
            id="email"
            {...formik.getFieldProps("email")}
            name="email"
            className="mb-3"
            type="text"
            placeholder="Email"
            label="" 
            variant="outlined" 
            fullWidth
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            InputProps={{
              startAdornment: 
              <InputAdornment position="start">
                <MailOutlineIcon color="disabled" />
              </InputAdornment>,
            }} />
          <TextField
            id="password"
            {...formik.getFieldProps("password")}
            name="password"
            className="mb-3"
            type="password"
            placeholder="Password"
            label="" 
            variant="outlined" 
            fullWidth
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              startAdornment: 
              <InputAdornment position="start">
                <VpnKeyOutlinedIcon color="disabled" />
              </InputAdornment>,
            }} />
          <div 
            style={{ color: '#00502F', fontSize: '14px' }} 
            className="text-right">
              <span style={{ cursor: 'pointer' }} onClick={() => history.push({ pathname: '/password-reset' }) }>Forgot password?</span>
          </div>
          <ColorButton disabled={formik.isSubmitting} type="submit" className="mt-5" fullWidth variant="contained" color="primary">
            LOGIN
          </ColorButton>
          {loading && <LinearProgress />}
        </form>
      </div>
    </>
  )
}

export default Login
