import React, { useEffect, useState, useCallback } from 'react';
import Header from '../../components/Header';
import { TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Image, Row, Col } from 'react-bootstrap';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from "react-router-dom";
import { customAxios } from '../../api/customAxios';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import JSZipUtils from '../../utils/JSZipUtils';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    marginBottom: 16,
    maxWidth: 600,
    flexGrow: 1,
    width: '90%',
    position: 'relative'
  },
  date: {
    fontSize: '12px',
    color: '#00502F'
  },
  btn: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
  },
  download: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    backgroundColor: '#00502F',
    color: '#fff',
    position: 'fixed',
    bottom: '15px',
    right: '25px'
  },
  textDownload: {
    position: 'fixed',
    bottom: '80px',
    right: '15px',
    textAlign: 'center',
    fontSize: '13px',
    width: '80px',
  },
  iconDownload: {
    cursor: 'pointer',
    position: 'absolute',
    bottom: '5px',
    right: '5px',
    fontSize: '50px',
    alignItems: 'center',
    textAlign: 'center'
  }
}));

const urlDoc = process.env.REACT_APP_UPLOADS + 'dokumen/';

const Documents = () => {
  const classes = useStyles();
  let history = useHistory();

  const handleRead = data => {
    if (data?.content){
      history.push({
        pathname: `/health-and-safety/detail/${data?.id_document}`
      })
    }
  }

  const handleView = data => {
    if (data?.file){
      return history.push({
        pathname: `/health-and-safety/view/${data?.id_document}`
      })
    }
  }

  const [loadData, setLoadData] = useState(true);
  const [arrData, setArrData] = useState([]);
  const [textSearch, setTextSearch] = useState("");
  const [urlPDF, setUrlPDF] = useState([]);
  const [loadNewItems, setLoadNewItems] = useState(true);
  const [newItems, setNewItems] = useState([]);

  useEffect(() => {
    if(loadData){
      customAxios('/document-kategori/Health and Safety').then(response => {
        if (response.status === 200) {
          const arr = response.data.data
          setArrData(arr)
          arr.map(data => {
            return setUrlPDF(old => [...old, data.file])
          })
        }
      }).catch(err => console.log(err.message))
      setLoadData(false)
    }
  }, [loadData]) 

  useEffect(()=>{
    if(loadNewItems){
      const users = JSON.parse(localStorage.getItem('users'));
      customAxios(`/notifikasi/Health and Safety/${users.id}`)
      .then(response=>{
        if(response.status === 200){
          setNewItems([]);
          response.data.data.map(dt => {
            if (dt.flagid === 0 && dt.idn !== null){
              return setNewItems(newItems => [...newItems, dt.idn])
            }
            return null;
          })
          setLoadNewItems(false)
        }
      })
    }
  }, [loadNewItems])


  const onDeleteItems = useCallback(
    () => {
      const users = JSON.parse(localStorage.getItem('users'));
      newItems.map(data => {
        return customAxios.post('/notifikasi/resetnotifikasi', {
          category: 'Health and Safety',
          id: users.id,
          idn: data
        })
          .then(() => {
            setLoadNewItems(true);
            setNewItems([]);
          })
          .catch(error => console.log(error.message))
      });
    },
    [newItems]
  );

  useEffect(()=>{
    if(newItems.length > 0){
      const timer = () => setTimeout(() => onDeleteItems(), 2000);
      const timerId = timer();
      return () => {
        clearTimeout(timerId);
      };
    }
  },[newItems, onDeleteItems])
  
  const downloadAll = () => {
    var zip = new JSZip();
    var count = 0;
    var zipFilename = "download-all-documents.zip";
    urlPDF.forEach(function (url) {
        JSZipUtils.getBinaryContent(urlDoc + url, function (err, data) {
            if (err) {
                throw err; // or handle the error
            }
            zip.file(url, data, {
                binary: true, 
                createFolders: true 
            });
            count++;
            if (count === urlPDF.length) {
                zip.generateAsync({
                        type: "blob"
                    })
                    .then(function (blob) {
                        saveAs(blob, zipFilename);
                    });
            }
        });
    });
  }

  const handleDownload = data => {
    axios({
      url: urlDoc + data,
      method: 'GET',
      responseType: 'blob',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf');
      document.body.appendChild(link);
      link.click();
    }).catch(() => alert('File not uploaded!'));
  }

  return (
    <>
      <Header link="/home" />
      <div className={classes.root}>
        <TextField
          className="mb-2"
          type="search"
          placeholder="Search Documents"
          label="" 
          variant="outlined" 
          fullWidth
          onChange={e => setTextSearch(e.target.value.toLowerCase())}
          InputProps={{
            startAdornment: 
            <InputAdornment position="start">
              <SearchIcon color="disabled" />
            </InputAdornment>,
          }} />
        {
          arrData
            .filter(item => item.name_doc.toLowerCase().includes(textSearch) )
            .map(data => (
              <div key={data.id_document} 
                className={newItems.length > 0 && newItems.includes(data.id_document) ? 'contact-highlight pt-2' : 'pt-2'}>
                <Row>
                  <Col className="d-flex align-items-center justify-content-center" xs={4} md={4}>
                    <Image width="80%" src={process.env.PUBLIC_URL + "/media/img/pdf.png"} />
                  </Col>
                  <Col xs={8} md={8}>
                    <Row>
                      <h6>{data.name_doc}</h6>
                    </Row>
                    <Row>
                      <span className={classes.date}>
                        {moment(data.created_at).format('dddd, MMMM D YYYY')}
                      </span>
                    </Row>
                    <Row className={classes.btn}>
                      {
                        data?.file ? <>
                          <Button onClick={() => handleDownload(data?.file)} size="small" variant="outlined">Download PDF</Button>
                          <Button onClick={() => handleView(data)} size="small" variant="outlined">View PDF</Button>  
                        </> : null
                      }
                      {
                        data?.content ?
                          <Button onClick={() => handleRead(data)} size="small" variant="outlined">Read</Button>
                        : null
                      }
                    </Row>
                  </Col>
                </Row>
                <hr />
              </div>
          ))
        }
        <b className={classes.textDownload}>Download All</b>
        <div className={classes.download} onClick={()=>downloadAll()}>
          <GetAppIcon className={classes.iconDownload} />
        </div>
      </div>
    </>
  )
}

export default Documents;