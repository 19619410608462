import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import Header from '../../components/Header';
import { makeStyles } from '@material-ui/core/styles';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import "./DocumentView.css";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/umd/Page/AnnotationLayer.css';
import { customAxios } from '../../api/customAxios';
import moment from 'moment';
// import ZoomInIcon from '@material-ui/icons/ZoomIn';
// import ZoomOutIcon from '@material-ui/icons/ZoomOut';
// import { Box } from '@material-ui/core';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    maxWidth: 600,
    flexGrow: 1,
    width: '100%'
  },
}));

const urlDoc = process.env.REACT_APP_UPLOADS + 'dokumen/';

const DocumentView = () => {
  const classes = useStyles();
  let { idDetail } = useParams();

  const refDoc = useRef(0)
  const [numPages, setNumPages] = useState(null);
  const [loadData, setLoadData] = useState(true)
  const [arrData, setArrData] = useState(null)
  const [notFound, setNotFound] = useState(0)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  useEffect(() => {
    if(loadData){
      customAxios(`/document-detail/${idDetail}`).then(response => {
        if (response.status === 200) {
          setArrData(response.data.data)
          setNotFound(0)
        }
      }).catch(err => {
        console.log(err.message)
        setArrData(null)
        setNotFound(1)
      })
      setLoadData(false)
    }
  }, [loadData, idDetail])

  return (
    <>
      <Header link="/health-and-safety" />
      {
        arrData ? (
          <div className={classes.root}>
            <div className="px-3">
              <h6>{arrData.name_doc}</h6>
              <p>{moment(arrData.created_at).format('ddd, MMMM D, YYYY / h:mm A')}</p>
            </div>
          
            <TransformWrapper
              panning={{
                excluded: ['canvas', 'div', 'span'],
              }}
              wheel={{
                disabled: true
              }}>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <>
                <TransformComponent>
                  <div ref={refDoc}>
                    <Document
                      file={urlDoc + arrData.file}
                      options={{ workerSrc: "/pdf.worker.js" }}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                      ))}
                    </Document>
                  </div>
                </TransformComponent>

                {/* <Box sx={{ position: 'fixed', zIndex: 100, right: 32, bottom: 32 }}>
                  <Box
                    onClick={() => zoomIn()}
                    sx={{ 
                      p: 1.5,
                      mb: 1.5,
                      bgcolor: '#FFF',
                      color: '#00502F',
                      cursor: 'pointer',
                      borderRadius: '50%',
                      boxShadow: 'rgba(0, 0, 0, 0.5) 0px 0px 3px, rgba(0, 0, 0, 0.5) 0px 0px 3px',
                    }}>
                    <ZoomInIcon style={{ fontSize: 28 }} />
                  </Box>
                  <Box
                    onClick={() => zoomOut()}
                    sx={{
                      p: 1.5,
                      bgcolor: '#FFF',
                      color: '#00502F',
                      cursor: 'pointer',
                      borderRadius: '50%',
                      boxShadow: 'rgba(0, 0, 0, 0.5) 0px 0px 3px, rgba(0, 0, 0, 0.5) 0px 0px 3px'
                    }}>
                    <ZoomOutIcon style={{ fontSize: 28 }} />
                  </Box>
                </Box> */}
              </>
            )}
            </TransformWrapper>
              

            
          </div>
        ) : notFound === 1 ? (
          <h1 className="text-center">NOT FOUND</h1>
        ) : null 
      }
    </>
  )
}

export default DocumentView;
