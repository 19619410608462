import React from 'react';
import Header from '../../components/Header';
import BotNav from '../../components/BotNav';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    maxWidth: 600,
    flexGrow: 1,
    width: '90%'
  }
}));

export const ContactAdmin = () => {
  const classes = useStyles();
  return (
    <>
      <Header />
      <div className={classes.root}>
        <h5 className="text-center mt-4 mb-5">
          Contact Admin
        </h5>
        <h6>Harityas Wiyoga</h6>
        <List component="nav" aria-label="main mailbox folders">
          <ListItem button
            onClick={()=> window.location.href = 'tel:+628121185105'}>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText primary="Phone Number" secondary="+628121185105" />
          </ListItem>
          <ListItem button
            onClick={()=> window.location.href = 'mailto:harityas.wiyoga@fs-ip.us'}>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary="Email Address" secondary="harityas.wiyoga@fs-ip.us" />
          </ListItem>
        </List>
      </div>
      <BotNav />
    </>
  )
}
