export const select_unit = [
  {
    id: 2,
    label: "Asia Pacific",
    value: "AP",
  },
  {
    id: 3,
    label: "Africa and Middle East",
    value: "AME",
  },
  {
    id: 4,
    label: "Latin America, Carribean, and Canada",
    value: "LACC",
  },
  {
    id: 5,
    label: "Europe and Eurasia",
    value: "EE",
  },
  {
    id: 6,
    label: "Director’s Office",
    value: "DO",
  },
  {
    id: 7,
    label: "Disaster Assistance Support Program",
    value: "DASP",
  },
  {
    id: 8,
    label: "Global Programs",
    value: "GP",
  },
  {
    id: 9,
    label: "Migratory Species",
    value: "MS",
  },
  {
    id: 10,
    label: "Operations",
    value: "OP",
  },
  {
    id: 12,
    label: "Urban Outreach and Partnerships",
    value: "UOP",
  },
  {
    id: 13,
    label: "Trade",
    value: "WPT",
  },
  {
    id: 14,
    label: "Workplace Environment, Information, and Learning",
    value: "WEIL",
  },
  {
    id: 15,
    label: "FSIF",
    value: "FS",
  },
];

export const val_all_unit = [
  "AP",
  "AME",
  "LACC",
  "EE",
  "DO",
  "DASP",
  "GP",
  "MS",
  "OP",
  "PO",
  "UOP",
  "WPT",
  "WEIL",
  "FS",
];
